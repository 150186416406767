import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import ShinyButton from '../../components/ShinyButton';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import { useForm } from 'react-hook-form';

import './style.scss';
import ConsentCheckbox from '../../components/ConsentCheckbox';

const SeniorGraphicDesignerPage = () => {
    const { getValues, setValue } = useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFromlocalStorage, setdataFromlocalStorage] = useState();
    const [resumeFileName, setResumeFileName] = useState('');

    useEffect(() => {
        retrieveDataFromLocalStorage();
    }, [currentPage]);

    const retrieveDataFromLocalStorage = () => {
        if (localStorage.getItem('resumeData') !== null) {
            const savedValues = JSON.parse(localStorage.getItem('resumeData'));
            setdataFromlocalStorage(savedValues);
            for (let key in savedValues) {
                setValue(key, savedValues[key]);
            }
        }
    };

    const checkUploadedFileSize = file => {
        if (file.size > 52428800) {
            alert('File is too big! Max resume size is 50MB.');
            file.value = '';
            return false;
        }
        return true;
    };

    const setUploadedFileName = (resume, file) => {
        if (checkUploadedFileSize(file[0])) {
            const selectedFile = file[0].name.split('\\');
            localStorage.setItem('resumeData', JSON.stringify(getValues()));

            if (resume === 'resume') {
                setValue('resume', file);
                setResumeFileName(
                    'File Uploaded: ' + selectedFile[selectedFile.length - 1]
                );
            }
        }
    };

    const uploadResume = () => {
        const resumeUpload = document.getElementById('resume');
        resumeUpload.click();
    };

    return (
        <Layout>
            <Seo title="QA Automation Engineer" />
            <div className="qa-automation-engineer-page">
                <Container className="purple-gradient-top container-1 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h1>QA Automation Engineer</h1>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-2 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h3>Who we’re looking for</h3>
                            <p className=" wrapLineSib">
                                We are seeking a QA Automation Engineer with 3+
                                years of experience. You should be able to
                                create automated regression and load tests for a
                                web application and perform manual testing of
                                upcoming features.
                            </p>
                            <p>
                                Besides that, being patient, hard-working, and
                                down for a good time is always a plus.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-3 purple-gradient-bottom">
                    <Row>
                        <Col className=" transparent-box max-width-900 margin-auto">
                            <h3>Responsibilities</h3>
                            <li className=" wrapLineSib">
                                Create and maintain automated end-to-end browser
                                tests
                            </li>
                            <li>
                                Conduct load testing (front-end and API-based)
                            </li>
                            <li>
                                Perform regression testing (manual and
                                automated)
                            </li>
                            <li>Initial manual testing of upcoming features</li>
                            <li>
                                Collaborate closely with development teams to
                                provide timely defect feedback
                            </li>
                            <li>
                                Stay up-to-date with industry developments and
                                tools
                            </li>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="transparent-box-2 max-width-900 margin-auto">
                            <h3>Required Skills</h3>
                            <li className=" wrapLineSib">
                                3+ years of QA testing experience
                            </li>
                            <li>
                                Proficiency in automated testing tools such as
                                Selenium, Cypress, or Playwright
                            </li>
                            <li>
                                Experienced in API and load testing tools like
                                Postman or JMeter
                            </li>
                            <li>
                                Capable of performing comprehensive manual
                                testing when required
                            </li>
                            <li>
                                Experience with creating test plans and reports
                            </li>
                            <li>
                                Strong analytical skills and attention to detail
                            </li>
                            <li>
                                Ability to learn, take direction and handle
                                critiques
                            </li>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-4  ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <AppForm
                                formName="careers-form"
                                submitBtnText="Send"
                                className="pink-form careers-form"
                                formCategory="Careers"
                            >
                                <h3 class="centered-text">
                                    Apply now for QA Automation Engineer
                                </h3>
                                <AppInput
                                    fieldName="firstName"
                                    type="text"
                                    labelText="What's your name?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    labelText="What's your email address?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="phone"
                                    type="text"
                                    labelText="What's your phone number?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="why"
                                    type="text"
                                    labelText="Why do you want to work for GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="goal"
                                    type="text"
                                    labelText="What do you want to achieve with a career at GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="passion"
                                    type="text"
                                    labelText="What are you passionate about? (Doesn't have to be business related)"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="movie"
                                    type="text"
                                    labelText="What's your favorite movie?"
                                />
                                <div className="file centered-text">
                                    <label
                                        htmlFor="resume"
                                        className="custom-file-upload"
                                    >
                                        <input
                                            type="file"
                                            name="resume"
                                            id="resume"
                                            className="file-upload"
                                            required
                                            onChange={e => {
                                                setUploadedFileName(
                                                    'resume',
                                                    e.target.files
                                                );
                                            }}
                                        />
                                        <p className="choose-a-file">
                                            Drop video resume/cover letter here
                                            or
                                            <ShinyButton
                                                className="btn white create-free-account-button"
                                                onClick={() => uploadResume()}
                                            >
                                                Select Files
                                            </ShinyButton>
                                        </p>
                                        <p id="fileUploadedName">
                                            {resumeFileName}
                                        </p>
                                    </label>
                                </div>
                                <AppInput
                                    fieldName="video"
                                    type="text"
                                    labelText="Video or Portfolio URL"
                                />
                                <div style={{ marginBottom: 20 }}>
                                    <ConsentCheckbox />
                                </div>
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default SeniorGraphicDesignerPage;
